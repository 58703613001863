import React from 'react';
import { concernLevelColorMap } from './helperConstants';
const Circle = ({
  type,
  text = null,
  smallTopMargin = true,
}: {
  type: string;
  text?: string | null;
  smallTopMargin?: boolean;
}) => {
  const color = concernLevelColorMap[type as keyof typeof concernLevelColorMap];

  const circleStyles = {
    width: text ? '16px' : '8px', // Make circle bigger if there's text
    height: text ? '16px' : '8px',
    backgroundColor: color,
    borderRadius: '50% ',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'white', // Text color
    fontSize: '8px', // Adjust text size as needed
    fontWeight: 'bold', // Optional: make text bold,
    marginTop: smallTopMargin ? '2px' : '10px',
  };

  return <div style={circleStyles}>{text}</div>;
};

export default Circle;
