import React from 'react';
import { Container } from 'react-bootstrap';
import AppIcon from '../../../assets/images/app-icon.png'; // Add your app icon
import './joinWaitlist.css';

import CollectInfo from './CollectInfo';
interface JoinWaitlistProps {
  setToastMessage: (message: string) => void;
  setShowToast: (show: boolean) => void;
}

const JoinWaitlist = ({ setToastMessage, setShowToast }: JoinWaitlistProps) => {
  return (
    <section className="join-waitlist" id="join-waitlist">
      <Container>
        <div className="waitlist-container">
          <div className="app-info">
            <div className="app-icon-wrapper">
              <img src={AppIcon} alt="Morph App Icon" className="app-icon" />
            </div>
            <div className="app-text">
              <h2>Morph App on App Store / Google Play</h2>
              <p>Accelerate your inspection work</p>
            </div>
          </div>
          <CollectInfo
            setToastMessage={setToastMessage}
            setShowToast={setShowToast}
          />
        </div>
      </Container>
    </section>
  );
};

export default JoinWaitlist;
