import { SurveyData } from './MainContent/SurveyModal/SurveyModal';

export const isValidEmail = (email: string) => {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
};

export const handleJoinWaitlist = async (
  email: string,
  setShowToast: (show: boolean) => void,
  setToastMessage: (message: string) => void,
  setShowSurvey: (show: boolean) => void,
) => {
  if (email) {
    const showSurvey: boolean = await addUserToWaitlist(
      email,
      setShowToast,
      setToastMessage,
    );
    setShowSurvey(showSurvey);
  }
};

export const addUserToWaitlist = async (
  email: string,
  setShowToast: (show: boolean) => void,
  setToastMessage: (message: string) => void,
): Promise<boolean> => {
  const formData = {
    email,
  };

  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(formData),
  };

  try {
    const controller = new AbortController();
    const timeoutId = setTimeout(() => controller.abort(), 3000);

    const response = await fetch(
      `https://hook.us2.make.com/yfzey7ogdgnqxqki2w1ii53k3rtq62hg`,
      {
        ...requestOptions,
        signal: controller.signal,
      },
    );

    clearTimeout(timeoutId);

    if (response.ok) {
      setShowToast(true);
      setToastMessage(`${email} has joined the waitlist.`);
      return true;
    } else if (response.status === 400) {
      setShowToast(true);
      setToastMessage('Unable to Save. Check connection and try again.');
      return false;
    } else {
      setShowToast(true);
      setToastMessage('Something went wrong. Please contact us for help.');
      return false;
    }
  } catch (error) {
    console.error('Error adding user to waitlist:', error);
    setShowToast(true);

    if (error instanceof DOMException && error.name === 'AbortError') {
      setToastMessage(
        'Request timed out. Please check your connection and try again.',
      );
    } else {
      setToastMessage('Something went wrong. Please contact us for help.');
    }

    return false;
  }
};

export const handleSubmitSurvey = async (
  surveyData: SurveyData,
  setShowToast: (show: boolean) => void,
  setToastMessage: (message: string) => void,
) => {
  console.log(surveyData);

  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(surveyData),
  };
  try {
    const response = await fetch(
      `https://hook.us2.make.com/yfzey7ogdgnqxqki2w1ii53k3rtq62hg`,
      requestOptions,
    );

    if (response.ok) {
      setShowToast(true);
      setToastMessage('Background info submitted.');
    } else if (response.status === 400) {
      setShowToast(true);
      setToastMessage('Unable to Save. Check connection and try again.');
    } else {
      setShowToast(true);
      setToastMessage('Something went wrong. Please contact us for help.');
    }
  } catch (error) {
    console.error('Error submitting survey:', error);
    setShowToast(true);
    setToastMessage('Something went wrong. Please contact us for help.');
  }
};
