import React from 'react';
import { Row, Col } from 'react-bootstrap';
import './heroSection.css';
import HeroImageLeft from '../../../assets/images/hero-image-left.png';
import HeroImageRight from '../../../assets/images/hero-image-right.png';
import CollectInfo from '../JoinWaitlist/CollectInfo';

const HeroSection = ({
  setToastMessage,
  setShowToast,
}: {
  setToastMessage: (message: string) => void;
  setShowToast: (show: boolean) => void;
}) => {
  return (
    <section className="hero">
      <div className="hero-inner">
        <Row>
          {/* Images Column (1-6) */}
          <Col xxl={6} xl={6} md={4} className="position-relative order-lg-1">
            <div className="hero-images">
              <div className="image-container">
                <img
                  src={HeroImageLeft}
                  alt="App Screenshot 1"
                  className="phone-left img-fluid"
                />
                <img
                  src={HeroImageRight}
                  alt="App Screenshot 2"
                  className="phone-right img-fluid"
                />
              </div>
            </div>
          </Col>

          {/* Text Content Column (7-12) */}
          <Col xxl={6} xl={6} md={8} className="hero-text-column order-lg-2">
            <div className="hero-content">
              <div className="text-wrapper">
                <h1 className="hero-title">
                  <span className="small-title">From Photos to Reports</span>
                  <span className="d-block  bold-title">AI at Your</span>
                  <span className="d-block  bold-title big-title">Service</span>
                </h1>
              </div>

              <CollectInfo
                setToastMessage={setToastMessage}
                setShowToast={setShowToast}
              />
            </div>
          </Col>
        </Row>
      </div>
    </section>
  );
};

export default HeroSection;
