import React, { useState, useRef } from 'react';
import Steps from 'rc-steps';
import { Button } from 'react-bootstrap';
import 'rc-steps/assets/index.css';
import './tryItOut.css';
import ClipLoader from 'react-spinners/ClipLoader';
import Circle from '../../VisualAssets/Circle';
import { concernLevelColorMap } from '../../VisualAssets/helperConstants';
import uploadImages from '../../../assets/images/upload-images.png';
import errorIcon from '../../../assets/images/error.svg';
import CollectInfo from '../JoinWaitlist/CollectInfo';

const steps = [
  {
    title: 'Step1',
    description: 'Upload Deficiency Photos',
  },
  {
    title: 'Step2',
    description: 'Select Concerning Level',
  },
  {
    title: 'Step3',
    description: 'AI Analyze',
  },
];

const CONCERN_LEVELS_MAP: Record<
  string,
  { label: string; value: number; color: string }
> = {
  0: {
    label: 'Maintenance Item',
    value: 0,
    color: concernLevelColorMap['concern-maintenance'],
  },
  1: {
    label: 'Recommendation',
    value: 1,
    color: concernLevelColorMap['concern-recommendation'],
  },
  2: {
    label: 'Safety Hazard',
    value: 2,
    color: concernLevelColorMap['concern-hazard'],
  },
};

interface TryItOutProps {
  isMobile: boolean;
  isTablet: boolean;
  setToastMessage: (message: string) => void;
  setShowToast: (showToast: boolean) => void;
}

interface AnalysisData {
  description: string;
  cause: string;
  solution: string;
  name: string;
  section: string;
  subsection: string;
  code_1: Code;
  code_2: Code;
  code_3: Code;
  concern_level: number;
}

interface Code {
  code: string;
  content: string;
  section: string;
}

const TryItOut = ({
  isMobile,
  isTablet,
  setToastMessage,
  setShowToast,
}: TryItOutProps) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [imageFiles, setImageFiles] = useState<File[]>([]);
  const [concerningLevel, setConcerningLevel] = useState('');
  const [briefNote, setBriefNote] = useState('');
  const [showLoading, setShowLoading] = useState(false);
  const [analysisData, setAnalysisData] = useState<AnalysisData | null>(null);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [showJoinWaitlist, setShowJoinWaitlist] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleBrowseClick = () => {
    setShowToast(false);
    fileInputRef.current?.click();
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;

    if (files && files.length > 0) {
      const imagesArray = Array.from(files).filter((file) =>
        file.type.startsWith('image/'),
      );

      const uploadImagesCount = files.length;

      if (uploadImagesCount > 10) {
        setToastMessage('Maximum 10 photos allowed.');
        setShowToast(true);
        event.target.value = '';
        return;
      }

      setImageFiles((prevFiles) => [...prevFiles, ...imagesArray]);
    }

    // Reset the input value to allow same file selection
    event.target.value = '';
  };

  const direction = isMobile || isTablet ? 'horizontal' : 'vertical';

  /* Drag & Drop */

  const handleFiles = (files: FileList | File[]) => {
    setShowToast(false);

    const imageFiles = Array.from(files).filter((file) =>
      file.type.startsWith('image/'),
    );

    if (imageFiles.length > 10) {
      setToastMessage('Maximum 10 images allowed');
      setShowToast(true);
      return;
    }

    if (imageFiles.length > 0) {
      setImageFiles((prevFiles) => [...prevFiles, ...imageFiles]);
    }
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault(); // This is crucial
  };

  const handleDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    e.currentTarget.classList.add('dragging');
  };

  const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    e.currentTarget.classList.remove('dragging');
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();

    // Access files through DataTransferItems
    const items = Array.from(e.dataTransfer.items);
    const files: File[] = [];

    items.forEach((item) => {
      if (item.kind === 'file') {
        const file = item.getAsFile();
        if (file) files.push(file);
      }
    });

    if (files.length > 0) {
      handleFiles(files);
    }
  };

  const resetAll = () => {
    setImageFiles([]);
    setCurrentStep(0);
    setConcerningLevel('');
    setBriefNote('');
    setShowErrorMessage(false);
  };

  const handleAIAnalyze = async () => {
    setShowLoading(true);

    try {
      // Convert images to base64
      const imagePromises = imageFiles.map((file) => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onload = () => {
            // Get base64 string without the prefix
            const base64String = reader.result as string;
            const base64WithoutPrefix = base64String.split(',')[1];
            resolve(base64WithoutPrefix);
          };
          reader.onerror = reject;
          reader.readAsDataURL(file);
        });
      });

      const base64ImagesPromise = await Promise.all(imagePromises);
      const base64Images = await base64ImagesPromise;

      const formData = {
        images: base64Images,
        concern_level: CONCERN_LEVELS_MAP[concerningLevel].value,
        description_hint: briefNote,
      };
      console.log('209');
      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      };

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/issues/generate/anonymous/`,
        requestOptions,
      );

      console.log('response', response);

      if (!response.ok) {
        if (response.status === 429) {
          setShowJoinWaitlist(true);
          return;
        }
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();

      setAnalysisData(data);
      setShowLoading(false);
      setCurrentStep(2);
    } catch (error: unknown) {
      setShowLoading(false);

      setShowErrorMessage(true);
      if (error instanceof Error) {
        console.error('Fetch error:', error.message);
      } else {
        console.error('An unknown error occurred');
      }
    }
  };

  const renderInteractionArea = () => {
    if (showLoading) {
      return (
        <div className="preview-area">
          <div className="loading-container">
            <ClipLoader
              color={'#075AD8'}
              loading={showLoading}
              cssOverride={{
                display: 'block',
                margin: '0 auto',
              }}
              size={64}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
            <div className="loading-text mt-3" style={{ color: '#222222' }}>
              <p>Analyzing...</p>
            </div>
          </div>
        </div>
      );
    }

    if (showErrorMessage) {
      return (
        <div className="preview-area">
          <div className="loading-container">
            <div className="text-center" style={{ color: '#222222' }}>
              <div className="error-icon d-flex justify-content-center mb-3">
                <img src={errorIcon} alt="Error" width={48} height={48} />
              </div>
              <p className="fw-bold mb-2">
                Something went wrong. Please try again.
              </p>
              <p className="mb-1">
                If the issue happens again, please contact us for help.
              </p>
              <div className="preview-actions d-flex gap-2 justify-content-center">
                <a
                  className="btn btn-outline-primary"
                  href="mailto:help@morph-technologies.com"
                >
                  Contact
                </a>
                <Button
                  variant="primary"
                  onClick={() => {
                    resetAll();
                  }}
                >
                  Try Again
                </Button>
              </div>
            </div>
          </div>
        </div>
      );
    }

    if (showJoinWaitlist) {
      return (
        <div className="preview-area">
          <div className="loading-container">
            <div className="text-center" style={{ color: '#222222' }}>
              <p className="fw-bold mb-2">
                We’re glad you’re enjoying our AI function!
              </p>
              <p className="mb-1">
                Join our waitlist. We’ll keep you updated and notify you as soon
                as it becomes available.
              </p>
              <CollectInfo
                setShowToast={setShowToast}
                setToastMessage={setToastMessage}
              />
            </div>
          </div>
        </div>
      );
    }

    if (currentStep === 2) {
      if (!analysisData) {
        return <div>No analysis data</div>;
      }

      const {
        name,
        concern_level,
        section,
        subsection,
        description,
        cause,
        solution,
        code_1,
      } = analysisData;

      return (
        <div className="analysis-step preview-area">
          <div className="preview-grid">
            {imageFiles.map((file, index) => {
              return (
                <div key={index} className="preview-item">
                  <img
                    src={URL.createObjectURL(file)}
                    alt={`Photo ${index + 1}`}
                    className="preview-image"
                  />
                </div>
              );
            })}
          </div>
          <div className="analysis-container">
            <div className="issue-wrapper">
              <div className="issue-title">Issue</div>
              <div className="issue-description">
                <span className="me-2">{name}</span>
                <span
                  className="concern-level-tag"
                  style={{
                    backgroundColor: CONCERN_LEVELS_MAP[concern_level].color,
                  }}
                >
                  {CONCERN_LEVELS_MAP[concern_level].label}
                </span>
              </div>
            </div>
            <div className="issue-wrapper">
              <div className="issue-title">Section</div>
              <div className="issue-description">
                {section}
                {subsection ? ` - ${subsection}` : ''}
              </div>
            </div>
            {description && (
              <div className="issue-wrapper">
                <div className="issue-title">Description</div>
                <div className="issue-description">{description}</div>
              </div>
            )}
            {cause && (
              <div className="issue-wrapper">
                <div className="issue-title">Cause</div>
                <div className="issue-description">{cause}</div>
              </div>
            )}
            {solution && (
              <div className="issue-wrapper">
                <div className="issue-title">Solution</div>
                <div className="issue-description">{solution}</div>
              </div>
            )}
            {code_1 && (
              <div className="issue-wrapper ">
                <div className="issue-title">Code</div>
                <div className="issue-description">
                  <div className="mb-2">
                    {code_1.code} {code_1.section}
                  </div>
                  <div>{code_1.content}</div>
                </div>
              </div>
            )}
          </div>
          <div className="preview-actions">
            <Button
              variant="outline-primary"
              onClick={() => {
                resetAll();
              }}
            >
              Retry
            </Button>
          </div>
        </div>
      );
    }

    if (currentStep === 1) {
      return (
        <div className="preview-area">
          <div className="preview-grid" style={{ display: 'block' }}>
            <div className="concerning-level-wrapper">
              <h6>Concerning Level*</h6>
              <div className="d-flex">
                <input
                  type="radio"
                  value="0"
                  id="maintenance-item"
                  name="concerning-level"
                  onChange={() => {
                    setConcerningLevel('0');
                  }}
                  checked={concerningLevel === '0'}
                />
                <label
                  className="concern-level-label"
                  htmlFor="maintenance-item"
                >
                  Maintenance Item <Circle type="concern-maintenance" />
                </label>
              </div>
              <div className="d-flex">
                <input
                  type="radio"
                  value="1"
                  id="recommendation"
                  name="concerning-level"
                  onChange={() => {
                    setConcerningLevel('1');
                  }}
                  checked={concerningLevel === '1'}
                />
                <label className="concern-level-label" htmlFor="recommendation">
                  Recommendation <Circle type="concern-recommendation" />
                </label>
              </div>
              <div className="d-flex">
                <input
                  type="radio"
                  value="2"
                  id="safety-hazard"
                  name="concerning-level"
                  onChange={() => {
                    setConcerningLevel('2');
                  }}
                  checked={concerningLevel === '2'}
                />
                <label className="concern-level-label" htmlFor="safety-hazard">
                  Safety Hazard <Circle type="concern-hazard" />
                </label>
              </div>
            </div>
            <div className="brief-note">
              <textarea
                placeholder="Brief note - Select from below or type your own (Optional)"
                value={briefNote}
                onChange={(e) => {
                  setBriefNote(e.target.value);
                }}
              />
              {briefNote === '' && (
                <div className="note-options">
                  <span style={{ color: '#343A40' }}>Common Issues: </span>
                  <Button
                    variant="outline-primary"
                    onClick={() => {
                      setBriefNote('Structural Concerns');
                    }}
                  >
                    Structural Concerns
                  </Button>
                  <Button
                    variant="outline-primary"
                    onClick={() => {
                      setBriefNote('Pest');
                    }}
                  >
                    Pest
                  </Button>
                  <Button
                    variant="outline-primary"
                    onClick={() => {
                      setBriefNote('Poor Ventilation');
                    }}
                  >
                    Poor Ventilation
                  </Button>
                  <Button
                    variant="outline-primary"
                    onClick={() => {
                      setBriefNote('Leaking');
                    }}
                  >
                    Leaking
                  </Button>
                </div>
              )}
            </div>
          </div>
          <div className="preview-actions">
            <Button
              variant="outline-primary"
              onClick={() => {
                resetAll();
              }}
            >
              Retry
            </Button>
            <Button
              className="analyze-button"
              variant="primary"
              onClick={() => {
                handleAIAnalyze();
              }}
              disabled={concerningLevel === ''}
            >
              AI Analyze
            </Button>
          </div>
        </div>
      );
    }

    if (currentStep === 0 && imageFiles.length === 0) {
      return (
        <div
          className="upload-area"
          onDragOver={handleDragOver}
          onDragEnter={handleDragEnter}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
        >
          <div className="upload-icon">
            <img src={uploadImages} alt="Upload" width={48} />
          </div>
          <h3>Drag & Drop Photos (Max 10)</h3>
          <p>or</p>
          <input
            type="file"
            ref={fileInputRef}
            onChange={handleFileChange}
            accept="image/*"
            multiple
            style={{ display: 'none' }}
          />
          <Button
            variant="primary"
            className="upload-button"
            onClick={handleBrowseClick}
          >
            Browse to Upload
          </Button>
        </div>
      );
    }

    return (
      <div className="preview-area">
        <div className="preview-grid">
          {imageFiles.map((file, index) => {
            return (
              <div key={index} className="preview-item">
                <img
                  src={URL.createObjectURL(file)}
                  alt={`Preview ${index + 1}`}
                  className="preview-image"
                />
              </div>
            );
          })}
        </div>
        <div className="preview-actions">
          <Button
            variant="outline-primary"
            onClick={() => {
              resetAll();
            }}
          >
            Retry
          </Button>
          <Button variant="primary" onClick={() => setCurrentStep(1)}>
            Continue
          </Button>
        </div>
      </div>
    );
  };

  return (
    <div className="try-it-out-container">
      <div className="steps-section">
        <Steps current={currentStep} direction={direction} items={steps} />
        <div className="note-text">*Add note to enhance accuracy</div>
      </div>
      <div className="interaction-section">{renderInteractionArea()}</div>
    </div>
  );
};

export default TryItOut;
