import React, { useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import './joinWaitlist.css';
import SurveyModal from '../SurveyModal/SurveyModal';
import { handleJoinWaitlist, isValidEmail } from '../../helperFunctions';

interface JoinWaitlistProps {
  setToastMessage: (message: string) => void;
  setShowToast: (show: boolean) => void;
}

const CollectInfo = ({ setToastMessage, setShowToast }: JoinWaitlistProps) => {
  const [email, setEmail] = useState('');
  const [showSurvey, setShowSurvey] = useState(false);

  const onHandleJoinWaitlist = () => {
    if (!isValidEmail(email)) {
      setShowToast(true);
      setToastMessage('Please enter a valid email address');
      return;
    }
    if (
      process.env.NODE_ENV === 'development' &&
      !email.endsWith('@testcrm.com')
    ) {
      if (
        !email.endsWith('@morph-technologies.com') &&
        !email.endsWith('@example.com')
      ) {
        setShowToast(true);
        setToastMessage(
          'Please test with a @example.com or @morph-technologies.com email address otherwise the email will be added to our CRM waitlist. If you want to test adding to the CRM waitlist, please use the @testcrm.com email address.',
        );
        return;
      }
    }

    handleJoinWaitlist(email, setShowToast, setToastMessage, setShowSurvey);
  };

  return (
    <>
      <div className="waitlist-form">
        <Form className="d-flex align-items-center gap-3">
          <Form.Control
            type="email"
            placeholder="Enter your email"
            size="lg"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <Button
            variant="primary"
            className="join-waitlist-btn"
            size="lg"
            onClick={onHandleJoinWaitlist}
            disabled={!email}
          >
            Join Waitlist
          </Button>
        </Form>
      </div>

      <SurveyModal
        show={showSurvey}
        onHide={() => setShowSurvey(false)}
        setShowToast={setShowToast}
        setToastMessage={setToastMessage}
        email={email}
        setEmail={setEmail}
      />
    </>
  );
};

export default CollectInfo;
