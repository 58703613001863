import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import './features.css';
import VideoWrapper from './VideoWrapper';
import SampleReport from '../../../assets/images/app-screenshot.png';
import TryItOut from '../TryItOut/TryItOut';

const SAMPLE_REPORT_URL =
  'http://app.morph-technologies.com/compiled-reports/cd2e1637-9b2d-4dbc-99ae-721ab171f608';

interface FeaturesProps {
  isMobile: boolean;
  isTablet: boolean;
  setToastMessage: (message: string) => void;
  setShowToast: (showToast: boolean) => void;
}

const Features = ({
  isMobile,
  isTablet,
  setToastMessage,
  setShowToast,
}: FeaturesProps) => {
  return (
    <section className="features">
      <Container className="features-container">
        <Row className="features-wrapper" id="ai-analysis">
          <Col lg={6}>
            <h2 className="features-title">AI-Powered Issue Analysis</h2>
            <div className="features-content">
              <p className="features-description mb-0">
                Analyze property defects with AI that turns photos into detailed
                insights.
              </p>
              <p className="features-description">
                Upload photos, select concerning level, and let out system
                pinpoint issues, suggest causes, and propose solutions for you—
                <span className="highlight">all in seconds</span>.
              </p>
            </div>
          </Col>
          <Col lg={6} className="features-quotes-wrapper ">
            <div className="features-quotes">
              <div className="quote-box">
                <p className="quote-text">
                  I no longer struggle to write detailed narratives.
                </p>
              </div>
              <div className="quote-box">
                <p className="quote-text">
                  Just upload photos, and the report is{' '}
                  <span className="d-block">ready!</span>
                </p>
              </div>
            </div>
          </Col>
        </Row>

        <Row className="features-wrapper ">
          <Col className="p-0">
            <div className="sample-report-header">
              <h2 className="sample-report-title">
                AI-Powered Insights - Try It Yourself
              </h2>
            </div>
            <TryItOut
              isMobile={isMobile}
              isTablet={isTablet}
              setToastMessage={setToastMessage}
              setShowToast={setShowToast}
            />
          </Col>
        </Row>

        <Row className="features-wrapper" id="note-taking">
          <Col lg={6}>
            <h2 className="features-title">Take Notes Effortlessly On-Site</h2>
            <div className="features-content">
              <p className="features-description">
                Streamline your note-taking process with{' '}
                <strong>voice-to-text technology</strong> and{' '}
                <strong>minimal on-screen tapping</strong>. Quickly capture
                details while staying engaged with clients, ensuring efficient
                communication and improved workflow.
              </p>

              <div className="features-quotes-wrapper">
                <div className="features-quotes">
                  <div className="quote-box">
                    <p className="quote-text">
                      I can keep taking notes while answering clients'
                      questions.
                    </p>
                  </div>
                  <div className="quote-box">
                    <p className="quote-text">
                      Clients are impressed by the effective communication
                      on-site.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col lg={6}>
            <VideoWrapper
              videoUrl={`https://morph-marketing-video-assets.s3.us-west-2.amazonaws.com/f2.mp4`}
            />
          </Col>
        </Row>

        <Row className="features-wrapper" id="photo-detection">
          <Col lg={6}>
            <h2 className="features-title">
              Detecting Annotated Photos &
              <span className="d-block">Enhancing Precision</span>
            </h2>
            <div className="features-content">
              <p className="features-description">
                Simply indicate the area of concern and let AI detect property
                issues like cracks or water damage with pinpoint accuracy.
              </p>
            </div>
            <div className="features-quotes-wrapper">
              <div className="features-quotes">
                <div className="quote-box">
                  <p className="quote-text">
                    AI precisely describes the defect I point out, saving me
                    tons of effort.
                  </p>
                </div>
                <div className="quote-box">
                  <p className="quote-text">
                    I just highlight the area, and the analysis focuses on it
                    instantly.
                  </p>
                </div>
              </div>
            </div>
          </Col>
          <Col lg={6}>
            <VideoWrapper
              videoUrl={`https://morph-marketing-video-assets.s3.us-west-2.amazonaws.com/f3.mp4`}
            />
          </Col>
        </Row>

        <Row className="features-wrapper" id="report-building">
          <Col lg={6}>
            <h2 className="features-title">Generate Reports as You Inspect</h2>
            <div className="features-content">
              <div className="features-description">
                Complete most of your inspection report during the site visit
                with AI-powered:
                <ul className="my-2">
                  <li>Auto-naming</li>
                  <li>Auto-categorizing</li>
                  <li>Analysis</li>
                </ul>
                Save time by finalizing reports on-site, eliminating the need
                for extensive post-visit edits.
              </div>
            </div>
            <div className="features-quotes-wrapper">
              <div className="features-quotes">
                <div className="quote-box">
                  <p className="quote-text">
                    No longer spend hours working on reports after I get home.
                  </p>
                </div>
                <div className="quote-box">
                  <p className="quote-text">
                    Reports are practically done by the time I leave the site!
                  </p>
                </div>
              </div>
            </div>
          </Col>
          <Col lg={6}>
            <VideoWrapper
              videoUrl={`https://morph-marketing-video-assets.s3.us-west-2.amazonaws.com/f4.mp4`}
            />
          </Col>
        </Row>

        <Row className="features-wrapper" id="smart-template">
          <Col lg={6}>
            <h2 className="features-title">
              Turn Your Templates Into Intelligent Tools
            </h2>
            <div className="features-content">
              <p className="features-description">
                Customize your reports effortlessly by importing your existing
                templates. Adjust tone and structure with AI to meet client
                needs while maintaining your personal style. Create
                professional, tailored reports at the click of a button.
              </p>
            </div>
            <div className="features-quotes-wrapper">
              <div className="features-quotes">
                <div className="quote-box">
                  <p className="quote-text">
                    I can maintain my own report pattern while embracing
                    advanced tech.
                  </p>
                </div>
                <div className="quote-box">
                  <p className="quote-text">
                    The flexibility allows me to adapt my report style for any
                    client.
                  </p>
                </div>
              </div>
            </div>
          </Col>
          <Col lg={6}>
            <VideoWrapper
              videoUrl={`https://morph-marketing-video-assets.s3.us-west-2.amazonaws.com/f5.mp4`}
            />
          </Col>
        </Row>

        <Row className="features-wrapper sample-report" id="sample-report">
          <Col>
            <div className="sample-report-header">
              <h2 className="sample-report-title">Sample Report</h2>
              <Button variant="primary" className="take-a-look-btn">
                <a href={SAMPLE_REPORT_URL} target="_blank" rel="noreferrer">
                  <span style={{ fontWeight: 700 }}>Take a Look</span>
                </a>
              </Button>
            </div>
            <div className="image-wrapper">
              <a href={SAMPLE_REPORT_URL} target="_blank" rel="noreferrer">
                <img src={SampleReport} alt="Sample Report" />
              </a>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Features;
