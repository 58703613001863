import React, { useState } from 'react';
import { Modal, Form, Button } from 'react-bootstrap';
import './surveyModal.css';
import { handleSubmitSurvey } from '../../helperFunctions';

interface SurveyModalProps {
  show: boolean;
  onHide: () => void;
  setShowToast: (show: boolean) => void;
  setToastMessage: (message: string) => void;
  email: string;
  setEmail: (email: string) => void;
}

export interface SurveyData {
  first_name: string;
  last_name: string;
  phone: string;
  state: string;
  role: string;
  inspection_experience: string;
  company_name: string;
  current_inspection_tools: string;
}

// generate all states and territories
// https://en.wikipedia.org/wiki/List_of_states_and_territories_of_the_United_States
const states = [
  'Alabama',
  'Alaska',
  'Arizona',
  'Arkansas',
  'California',
  'Colorado',
  'Connecticut',
  'Delaware',
  'Florida',
  'Georgia',
  'Hawaii',
  'Idaho',
  'Illinois',
  'Indiana',
  'Iowa',
  'Kansas',
  'Kentucky',
  'Louisiana',
  'Maine',
  'Maryland',
  'Massachusetts',
  'Michigan',
  'Minnesota',
  'Mississippi',
  'Missouri',
  'Montana',
  'Nebraska',
  'Nevada',
  'New Hampshire',
  'New Jersey',
  'New Mexico',
  'New York',
  'North Carolina',
  'North Dakota',
  'Ohio',
  'Oklahoma',
  'Oregon',
  'Pennsylvania',
  'Rhode Island',
  'South Carolina',
  'South Dakota',
  'Tennessee',
  'Texas',
  'Utah',
  'Vermont',
  'Virginia',
  'Washington',
  'West Virginia',
  'Wisconsin',
  'Wyoming',
  'Puerto Rico',
  'Guam',
  'Virgin Islands',
  'American Samoa',
  'Northern Mariana Islands',
];

const roles = ['Inspector', 'Company Owner', 'Apprentice', 'Other'];

const experienceLevels = [
  'Less than 1 year',
  '1-2 years',
  '3-5 Years',
  '6-10 Years',
  '11 Years+',
];
const initialFormData: SurveyData = {
  first_name: '',
  last_name: '',
  phone: '',
  state: '',
  role: '',
  inspection_experience: '',
  company_name: '',
  current_inspection_tools: '',
};

const SurveyModal: React.FC<SurveyModalProps> = ({
  show,
  onHide,
  setShowToast,
  setToastMessage,
  email,
  setEmail,
}) => {
  const [formData, setFormData] = useState<SurveyData>(initialFormData);

  const handleInputChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >,
  ) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleHide = () => {
    setEmail('');
    setFormData(initialFormData);
    onHide();
  };

  const onHandleSubmitSurvey = (e: React.FormEvent) => {
    e.preventDefault();
    const surveyData = {
      ...formData,
      email,
    };
    handleSubmitSurvey(surveyData, setShowToast, setToastMessage);
    handleHide();
  };

  // if all fields are empty, disable submit button
  // need at least one field filled
  const isSubmitDisabled = Object.values(formData).every(
    (value) => value === '',
  );

  return (
    <Modal
      show={show}
      onHide={handleHide}
      centered
      className="survey-modal"
      size="lg"
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title>Share Your Background</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Form onSubmit={onHandleSubmitSurvey}>
          <div className="d-flex gap-3 mb-3">
            <Form.Group className="flex-grow-1">
              <Form.Label>First Name</Form.Label>
              <Form.Control
                type="text"
                name="first_name"
                value={formData.first_name}
                onChange={handleInputChange}
              />
            </Form.Group>

            <Form.Group className="flex-grow-1">
              <Form.Label>Last Name</Form.Label>
              <Form.Control
                type="text"
                name="last_name"
                value={formData.last_name}
                onChange={handleInputChange}
              />
            </Form.Group>
          </div>

          <div className="d-flex gap-3 mb-3">
            <Form.Group className="flex-grow-1">
              <Form.Label>Phone</Form.Label>
              <Form.Control
                type="tel"
                name="phone"
                value={formData.phone}
                onChange={handleInputChange}
              />
            </Form.Group>

            <Form.Group className="flex-grow-1">
              <Form.Label>State</Form.Label>
              <Form.Select
                name="state"
                value={formData.state}
                onChange={handleInputChange}
              >
                <option value="">Select</option>
                {states.map((state) => (
                  <option key={state} value={state}>
                    {state}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </div>

          <Form.Group className="mb-3">
            <Form.Label>Role</Form.Label>
            <div className="d-flex gap-4">
              {roles.map((role) => (
                <Form.Check
                  key={role}
                  type="radio"
                  name="role"
                  value={role}
                  label={role}
                  id={`role-${role}`}
                  checked={formData.role === role}
                  onChange={handleInputChange}
                />
              ))}
            </div>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Inspection Experience</Form.Label>
            <div className="d-flex gap-3 flex-wrap">
              {experienceLevels.map((level) => (
                <Form.Check
                  key={level}
                  type="radio"
                  name="inspection_experience"
                  value={level}
                  label={level}
                  id={`exp-${level}`}
                  checked={formData.inspection_experience === level}
                  onChange={handleInputChange}
                />
              ))}
            </div>
          </Form.Group>

          <div className="d-flex gap-3 mb-4">
            <Form.Group className="flex-grow-1">
              <Form.Label>Company Name</Form.Label>
              <Form.Control
                type="text"
                name="company_name"
                value={formData.company_name}
                onChange={handleInputChange}
              />
            </Form.Group>

            <Form.Group className="flex-grow-1">
              <Form.Label>Current Inspection Tools</Form.Label>
              <Form.Control
                type="text"
                name="current_inspection_tools"
                value={formData.current_inspection_tools}
                onChange={handleInputChange}
              />
            </Form.Group>
          </div>

          <div className="text-end">
            <Button variant="primary" type="submit" disabled={isSubmitDisabled}>
              Submit
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default SurveyModal;
